@charset "UTF-8";
@font-face {
  font-family: 'ABCDiatype';
  src: local("../fonts/ABCDiatype-Bold"), url("../fonts/ABCDiatype-Bold.woff") format("woff"), url("../fonts/ABCDiatype-Bold.woff2") format("woff2");
  font-weight: bold; }

@font-face {
  font-family: 'ABCDiatype';
  src: local("../fonts/ABCDiatype-Light"), url("../fonts/ABCDiatype-Light.woff") format("woff"), url("../fonts/ABCDiatype-Light.woff2") format("woff2");
  font-weight: light; }

html,
body {
  font-family: 'ABCDiatype', helvetica, sans-serif;
  font-size: 100%;
  line-height: 1.25em;
  font-weight: light; }
  @media (min-width: 1200px) {
    html,
    body {
      font-size: 110%; } }
  @media (min-width: 1500px) {
    html,
    body {
      font-size: 120%; } }

@media (min-width: 1200px) {
  header#main-header nav, footer {
    font-size: 0.85rem;
    line-height: 1.25em; } }

.home .text, .home .quote {
  font-size: 1.25rem;
  line-height: 1.25em; }
  @media (min-width: 800px) {
    .home .text, .home .quote {
      font-size: 1.4rem; } }
  @media (min-width: 1200px) {
    .home .text, .home .quote {
      font-size: 1.6rem; } }

.site-title {
  font-size: 1.75rem;
  line-height: 1.2em; }
  @media (min-width: 800px) {
    .site-title {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .site-title {
      font-size: 3rem; } }

#container {
  margin: 15px 20px 20px; }
  @media (min-width: 800px) {
    #container {
      margin: 20px 50px; } }
  @media (min-width: 1300px) {
    #container {
      margin: 20px auto;
      max-width: 1200px; } }
  @media (min-width: 1500px) {
    #container {
      margin: 20px auto;
      max-width: 1300px; } }

.grid {
  display: grid;
  gap: 15px; }
  .grid p {
    padding-right: 2em; }
  @media (min-width: 800px) {
    .grid--2-c {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 800px) {
    .grid--center-c {
      grid-template-columns: repeat(4, 1fr); }
      .grid--center-c > div {
        grid-column-start: 2;
        grid-column-end: 4; } }
  @media (min-width: 500px) {
    .grid--4-c {
      grid-template-columns: repeat(2, 1fr); } }
  @media (min-width: 800px) {
    .grid--4-c {
      grid-template-columns: repeat(3, 1fr); } }
  @media (min-width: 1000px) {
    .grid--4-c {
      grid-template-columns: repeat(4, 1fr); } }

.sub-title {
  font-weight: 900; }

iframe {
  aspect-ratio: 2 / 1.5;
  width: 100%; }
  @media (min-width: 800px) {
    iframe {
      aspect-ratio: 2 / 1; } }

header#main-header {
  margin-bottom: 10vh; }
  @media (min-width: 800px) {
    header#main-header {
      margin-bottom: 15vh; } }
  header#main-header .logo {
    width: 170px;
    margin-bottom: 20px; }
    @media (min-width: 800px) {
      header#main-header .logo {
        width: 200px; } }
  @media (min-width: 800px) {
    header#main-header nav {
      justify-self: end;
      align-self: end;
      padding-bottom: 1em; } }
  header#main-header nav a {
    text-decoration: none;
    color: black; }
    header#main-header nav a.active {
      color: #0072a6; }
  header#main-header nav ul {
    display: flex;
    gap: 1em; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

img {
  max-width: 100%; }

section {
  margin-bottom: 120px; }
  @media (min-width: 800px) {
    section {
      margin-bottom: 150px; } }
  @media (min-width: 1200px) {
    section {
      margin-bottom: 175px; } }

p {
  margin-bottom: 1em; }

a {
  color: #0072a6;
  text-decoration: none;
  text-underline-offset: 0.25em; }

.home .climate-logo {
  width: 200px; }

.home .quote {
  font-family: 'Times New Roman', times, serif;
  font-style: italic;
  font-weight: 200; }

.news h2,
.news h3 {
  font-weight: bold; }

.news h2 {
  margin-bottom: 2em; }

.services h2 {
  font-weight: bold; }

.services .icon {
  height: 3.5em;
  width: 2.5em; }
  .services .icon img {
    width: 100%; }

.about .sub-title {
  margin-bottom: 1em; }

.about .team ul li {
  margin-bottom: 2em; }

.about figure {
  margin-bottom: 1em; }
  .about figure img {
    display: block; }

footer {
  margin-bottom: 2em; }
  footer .grid,
  footer .map {
    margin-bottom: 1em; }
  footer h2 {
    font-weight: bold; }

.link:before {
  color: #0072a6;
  content: '→ '; }
